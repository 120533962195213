import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import ProtectedLayout from "./ProtectedLayout";
import LoginForm from "./components/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import ProjectsList from "./components/ProjectsList";
import AssetsList from "./components/AssetsList";
import AssetView from "./components/AssetView";
import UsersList from "./components/UsersList";
import UserForm from "./components/UserForm";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <div className="flex flex-col mx-auto">
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <ProtectedLayout>
                  <Routes>
                    <Route path="projects-list" element={<ProjectsList />} />
                    <Route path="assets-list" element={<AssetsList />} />
                    <Route path="asset/:assetId" element={<AssetView />} />
                    <Route path="users-list" element={<UsersList />} />
                    <Route path="user" element={<UserForm />} />
                    <Route
                      path="*"
                      element={<Navigate replace to="/projects-list" />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
