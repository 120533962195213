import React from "react";

function UserForm() {
  return (
    <div className="p-6 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">User</h2>
      {/* User */}
    </div>
  );
}

export default UserForm;
