import React from "react";

function UsersList() {
  return (
    <div className="p-6 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">Users</h2>
      {/* List of users */}
    </div>
  );
}

export default UsersList;
