import React, { useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "./providers/AuthContext"; // Adjust the path as needed
import { Dropdown } from "react-bootstrap";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { ProjectContext } from "./providers/ProjectContext"; // Adjust the path as needed

function ProtectedLayout({ children }) {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { setCurrentProject, setCurrentAsset } = useContext(ProjectContext);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  // Simple function to convert pathnames into readable titles
  const formatTitle = (path) => {
    return path
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const breadcrumb = location.pathname
    .split("/")
    .filter((x) => x)
    .map((path, index, arr) => {
      const url = `/${arr.slice(0, index + 1).join("/")}`;
      return (
        <Link key={url} to={url} className="text-blue-600 hover:underline">
          {formatTitle(path)}
        </Link>
      );
    });

  return (
    <div className="flex flex-col w-full">
      <Navbar
        className="shadow-sm w-full z-20"
        bg="light"
        expand="lg"
        fixed="top"
      >
        <div className="flex-0 text-black p-2 flex flex-row justify-between items-center w-full">
          <div className="pl-5">
            <Navbar.Brand className="font-bold">VA - Tracer</Navbar.Brand>
            <Navbar.Brand>
              {formatTitle(breadcrumb[breadcrumb.length - 1].props.children)}
            </Navbar.Brand>
          </div>
          {/* <Nav className="me-auto">
                        <div>{breadcrumb.reduce((prev, curr, index) => [...prev, index > 0 ? ' / ' : '', curr], [])}</div>
                    </Nav> */}
          <Nav>
            <Nav.Link
              onClick={() => {
                setCurrentProject(null);
                setCurrentAsset(null);
                navigate("/projects-list");
              }}
            >
              Projects
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setCurrentProject(null);
                setCurrentAsset(null);
                navigate("/assets-list");
              }}
            >
              Assets
            </Nav.Link>
            <NavDropdown title={currentUser.email} id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item> */}
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
      </Navbar>
      <div className="z-10 min-h-screen">{children}</div>
    </div>
  );
}

export default ProtectedLayout;
