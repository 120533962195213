import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik"; // Make sure to include ErrorMessage
import * as Yup from "yup";
import { useAuth } from "../providers/AuthContext"; // Adjust the path as needed
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap";
import ErrorText from "./ErrorText";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

function ForgotPasswordForm() {
  const { forgotPassword } = useAuth();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await forgotPassword(values.email);
      setSubmitting(false);
      // Optionally, show a message or redirect the user after successful submission
    } catch (error) {
      // Handle errors (e.g., display a notification)
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-row justify-center">
      <div className="max-w-sm w-50 p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form>
              <FormGroup className="mb-4">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email && touched.email}
                  placeholder="Enter your email"
                  className="mt-1 block w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <FormText className="text-red-500">
                  <ErrorMessage name="email" component={ErrorText} />
                </FormText>
              </FormGroup>

              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
        <div className="mt-4">
          <Link to="/login" className="text-blue-600 hover:underline">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
