// apiService.js
import axios from "axios";

// const token = localStorage.getItem("token");

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const checkAuthState = async () => {
  try {
    const response = await api.get("/saml/check-auth", {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error checking auth state:", error);
    throw error;
  }
};

export const login = async () => {
  try {
    if (process.env.REACT_APP_ENV === "development") {
      window.location.href = `${process.env.REACT_APP_API_URL}/saml/dev-login`; // Adjust the URL to your server
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}/saml/login`; // Adjust the URL to your server
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.post(
      "/saml/logout",
      {},
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};

export const fetchProjects = async () => {
  try {
    const response = await api.get("/project/projects", {
      withCredentials: true,
    });
    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch projects", error);
    throw error;
  }
};

export const fetchSettings = async () => {
  try {
    const response = await api.get("/setting/settings", {
      withCredentials: true,
    });
    return response.data.data[0];
  } catch (error) {
    console.error("Failed to fetch settings", error);
    throw error;
  }
};

export const createAsset = async (assetData) => {
  console.log("assetData", assetData);
  try {
    const response = await api.post("/asset/create-asset", assetData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Failed to create asset", error);
    throw error;
  }
};

export const editAsset = async (assetId, assetData) => {
  try {
    const response = await api.post(`/asset/edit-asset/${assetId}`, assetData, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to edit asset with id ${assetId}`, error);
    throw error;
  }
};

export const fetchAssetsByProject = async (projectId) => {
  try {
    const response = await api.get(`/asset/project/${projectId}`, {
      withCredentials: true,
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to fetch assets for project ${projectId}`, error);
    throw error;
  }
};

export const fetchAllAssets = async () => {
  try {
    const response = await api.get(`/asset`, { withCredentials: true });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to fetch ALL assets`, error);
    throw error;
  }
};

export const fetchAsset = async (assetId) => {
  try {
    const response = await api.get(`/asset/${assetId}`, {
      withCredentials: true,
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to fetch asset ${assetId}`, error);
    throw error;
  }
};

export const addNoteToAsset = async (assetId, note) => {
  try {
    const response = await api.post(`/asset/${assetId}/addNote`, note, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to add note to asset with id ${assetId}`, error);
    throw error;
  }
};

export const addHistoryToAsset = async (assetId, history) => {
  try {
    const response = await api.post(`/asset/${assetId}/addHistory`, history, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to add history to asset with id ${assetId}`, error);
    throw error;
  }
};
