import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  checkAuthState as checkAuth,
  login as loginAPI,
  logout as logoutAPI,
} from "../services/apiService";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children, onLogin, onLogout }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const checkAuthState = useCallback(() => {
    checkAuth()
      .then((data) => {
        if (data.authenticated) {
          setCurrentUser(data.user);
          console.log(data.user);
        } else {
          setCurrentUser(null);
        }
      })
      .catch((error) => {
        console.error("Error checking auth state:", error);
      });
  }, []);

  const handleLogin = useCallback(() => {
    loginAPI().catch((error) => {
      console.error("Error during login:", error);
    });
  }, []);

  const logout = useCallback(() => {
    logoutAPI()
      .then(() => {
        setCurrentUser(null);
        onLogout && onLogout();
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }, [onLogout]);

  useEffect(() => {
    checkAuthState();
  }, [checkAuthState]);

  const value = {
    currentUser,
    login: handleLogin,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
