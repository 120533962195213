import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useAuth } from "./AuthContext";
import {
  fetchProjects,
  fetchSettings,
  fetchAllAssets,
} from "../services/apiService";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [projectList, setProjectList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [settings, setSettings] = useState(null);

  const currentProjectRef = useRef(currentProject);
  const currentAssetRef = useRef(currentAsset);

  useEffect(() => {
    currentProjectRef.current = currentProject;
    console.log("cp: ", currentProject);
  }, [currentProject]);

  useEffect(() => {
    currentAssetRef.current = currentAsset;
    console.log("ca: ", currentProject);
  }, [currentAsset]);

  const getProjects = useCallback(async () => {
    console.log("1");
    if (!currentUser) {
      setProjectList([]);
      setCurrentProject(null);
      return;
    }
    console.log("2");
    try {
      const projects = await fetchProjects();
      const sortedProjects = projects.sort((a, b) =>
        a.code > b.code ? -1 : 1
      );
      setProjectList(sortedProjects);
      console.log("3");
      // Update current project if it exists in the new list
      if (currentProjectRef.current) {
        const updatedProject = sortedProjects.find(
          (project) => project._id === currentProjectRef.current._id
        );
        console.log("4");
        console.log(updatedProject._id);
        console.log(currentProjectRef.current._id);
        if (
          updatedProject &&
          updatedProject._id === currentProjectRef.current._id
        ) {
          console.log("updated project: ", updatedProject);
          setCurrentProject(updatedProject);
        }
        console.log("5");
      }
      console.log("6");
    } catch (error) {
      console.log("7");
      setProjectList([]);
      setCurrentProject(null);
    }
  }, [currentUser?._id]);

  const getAssets = useCallback(async () => {
    if (!currentUser) {
      setAssetList([]);
      setCurrentAsset(null);
      return;
    }
    try {
      const assets = await fetchAllAssets();
      const sortedAssets = assets.sort((a, b) =>
        a.keyNumber > b.keyNumber ? -1 : 1
      );
      console.log(sortedAssets.length);
      setAssetList(sortedAssets);
      // Update current asset if it exists in the new list
      if (currentAssetRef.current) {
        const updatedAsset = sortedAssets.find(
          (asset) => asset._id === currentAssetRef.current._id
        );
        if (updatedAsset && updatedAsset._id !== currentAssetRef.current._id) {
          setCurrentAsset(updatedAsset || null);
        }
      }
    } catch (error) {
      setAssetList([]);
      setCurrentAsset(null);
    }
  }, [currentUser?._id]);

  const getSettings = useCallback(async () => {
    if (!currentUser) {
      setSettings(null);
      return;
    }
    try {
      const settings = await fetchSettings();
      setSettings(settings);
    } catch (error) {
      setSettings(null);
    }
  }, [currentUser?._id]);

  const getAll = useCallback(async () => {
    console.log("refresh");
    await getProjects();
    await getAssets();
    await getSettings();
  }, [getProjects, getAssets, getSettings]);

  useEffect(() => {
    if (currentUser) {
      getAll();
    }
  }, [getAll, currentUser?._id]);

  const value = {
    projectList,
    setProjectList,
    currentProject,
    setCurrentProject,
    assetList,
    setAssetList,
    currentAsset,
    setCurrentAsset,
    settings,
    refreshProjects: getProjects,
    refreshAssets: getAssets,
    refreshAll: getAll,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
