import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthContext";
import GoogleButton from "react-google-button";

function LoginForm() {
  const { currentUser, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard/projects-list");
    }
  }, [currentUser, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <title>va-tracer</title>
      <div className="text-center mb-6">
        <h1 className="text-4xl font-bold">Vandal Library</h1>
      </div>
      <div className="max-w-sm w-full p-10 bg-white shadow-md rounded-lg flex flex-col justify-center">
        <div className="flex flex-row justify-center">
          <h2 className="text-2xl font-bold mb-4">Login</h2>
        </div>
        <div className="flex flex-row justify-center">
          <GoogleButton onClick={login}></GoogleButton>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
