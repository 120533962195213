import React from "react";

const DisplayCreatedFields = ({ data }) => {
  const formatLocalTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="text-gray-400 text-sm">
      {data.createdBy && (
        <>
          <span className="font-semibold">Created By:</span>{" "}
          {data.createdBy.firstName} {data.createdBy.lastName}
          <br />
        </>
      )}
      {data.createdAt && (
        <>
          <span className="font-semibold">Created At:</span>{" "}
          {formatLocalTime(data.createdAt)}
          <br />
        </>
      )}
      {data.lastEditedBy && (
        <>
          <span className="font-semibold">Last Edited By:</span>{" "}
          {data.lastEditedBy.firstName} {data.lastEditedBy.lastName}
          <br />
        </>
      )}
      {data.lastEditedAt && (
        <>
          <span className="font-semibold">Last Edited At:</span>{" "}
          {formatLocalTime(data.lastEditedAt)}
          <br />
        </>
      )}
    </div>
  );
};

export default DisplayCreatedFields;
