import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { AuthProvider } from "./providers/AuthContext";
import { ProjectProvider } from "./providers/ProjectContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider
      onLogin={() => console.log("Logged in successfully")}
      onLogout={() => console.log("Logged out successfully")}
    >
      <ProjectProvider>
        <App />
      </ProjectProvider>
    </AuthProvider>
  </React.StrictMode>
);
